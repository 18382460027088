import { makeStyles } from '@material-ui/styles';
import { Button, CircularProgress } from '@material-ui/core';
import { AcUnitSharp, CheckCircle, Close } from '@material-ui/icons';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import theme from '../theme';
import useApplicationStore from '@services/stores/ApplicationStore';

const useStyles = makeStyles({
    wrapper: {
        display: 'flex',
        position: 'fixed',
        bottom: 25,
        left: 25,
    },
    iconButton: {
        backgroundColor: '#f0f0f0',
    },
    close: {
        position: 'absolute',
        zIndex: 10,
        top: -10,
        left: -10,
        width: 20,
        height: 20,
        padding: 1,
        backgroundColor: theme.palette.primary.color,
        color: theme.palette.primary.background,
        borderRadius: 3,
        cursor: 'pointer',
    },
    toolPopup: {
        position: 'absolute',
        bottom: '125%',
        left: 0,
        padding: 15,
        backgroundColor: '#f0f0f0',
        boxShadow: theme.boxShadow[0],
    },
    clearButton: {
        whiteSpace: 'nowrap',
        backgroundColor: theme.palette.primary.color,
        color: '#ffffff',
        '&:hover': {
            backgroundColor: '#444444',
        },
    },
}, { name: 'InternalTool' });

export default function InternalTool() {
    const classes = useStyles();
    const router = useRouter();
    const surrogateKeys = useApplicationStore((state) => state.surrogateKeys);
    const [ enable, setEnabled ] = useState(false);
    const [ show, setShow ] = useState(false);
    const [ loadingState, setLoadingState ] = useState('none');
    const allowedIpAddresses = [
        '62.181.237.166',
        '192.168.55.',
        '2a01:2b0:30c5:8888',
    ];

    useEffect(async () => {
        let cookieVal = Cookies.get('InternalTools');

        if ((cookieVal === 'true' && cookieVal !== 'undefined') || router.asPath.indexOf('?internalTool') >= 0) {
            let getIp = await fetch('/api/internal/getIp', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
            });

            let ipJson = await getIp.json();
            let ip = ipJson.ip.split(',')[0];

            allowedIpAddresses.forEach((allowedIp) => {
                if (ip === '::1' || ip.indexOf(allowedIp) >= 0) {
                    Cookies.set('InternalTools', true);
                    setEnabled(true);
                } else {
                    Cookies.set('InternalTools', false);
                    setEnabled(false);
                }
            });
        }
    }, []);

    async function clearCache() {
        console.log('internal cache keys', surrogateKeys);

        setLoadingState('loading');
        await fetch('https://interstellar' + '.newport.' + 'se/api/utility/' + 'cache-manager/web/purge-by-keys', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                keys: surrogateKeys,
            }),
        });
        setLoadingState('done');
    }

    function disableButton() {
        setEnabled(false);
        Cookies.remove('InternalTools');
    }

    function renderLoading() {
        if (loadingState === 'loading') {
            return <CircularProgress style={{ marginLeft: 6, width: 20, height: 20 }}></CircularProgress>;
        }

        if (loadingState === 'done') {
            setTimeout(() => {
                if (loadingState === 'done') {
                    setLoadingState('none');
                }
            }, 5000);
            return <CheckCircle style={{ marginLeft: 6 }} />;
        }
    }

    return (
        <>
            {enable ? <div className={classes.wrapper}>
                <Close className={classes.close} onClick={() => disableButton()} />
                <Button className={classes.iconButton} onClick={() => setShow(!show)}>
                    <AcUnitSharp fontSize="large"
                                 color="primary" /></Button>
                {show ? <div className={classes.toolPopup}>
                    <Button className={classes.clearButton} onClick={() => clearCache()}>
                        <span>Clear cache</span>
                        {renderLoading()}
                    </Button>
                </div> : ''}
            </div> : ''}
        </>
    );
}
