import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'tools/i18n';
import useApplicationStore from '@services/stores/ApplicationStore';
import { Close } from '@material-ui/icons';
import { useEffect, useRef } from 'react';

const useStyles = makeStyles({
    wrapper: {},
    notificationsBox: {
        display: 'flex',
        flexDirection: 'column',
        position: 'fixed',
        top: 0,
        right: 0,
        left: 0,
        minWidth: 280,
        width: '100%',
        zIndex: 1000,
        pointerEvents: 'none',
    },
    notify: {
        display: 'grid',
        marginLeft: 'auto',
        width: '100vw',
        minWidth: '300px',
        maxWidth: 'min(100vw, 400px)',
        overflowX: 'scroll',
        scrollSnapType: 'x mandatory',
        scrollbarWidth: 'none',
        gridTemplateColumns: '1fr 1fr',
    },
    spacer: {
        display: 'flex',
        flexGrow: 1,
        height: '100%',
        width: '100vw',
        minWidth: '300px',
        maxWidth: 'min(100vw, 400px)',
        scrollSnapAlign: 'start',
    },
    contentSection: {
        width: '100vw',
        minWidth: '300px',
        maxWidth: 'min(100vw, 400px)',
        opacity: 1,
        pointerEvents: 'all',
        scrollSnapAlign: 'end',
        padding: 8,
    },
    contentSectionBackground: {
        position: 'relative',
        borderRadius: 4,
        background: 'white',
        boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.1)',
        padding: 18,
    },
    content2: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: 8,
        minWidth: 'calc(300px * 2)',
        maxWidth: 'calc(400px * 2)',
        width: '200vw',
        margin: '0 8px',
    },
    closeButton: {
        position: 'absolute',
        top: 9,
        right: 9,
        cursor: 'pointer',
    },
    message: {
        marginBottom: 10,
    },
    removing: {
        opacity: 0,
        animation: `$fadeOut 0.2s ease-in-out forwards`,
    },
    clickable: {
        cursor: 'pointer',
    },
    // Keyframes for fade-in
    '@keyframes fadeIn': {
        '0%': { opacity: 0, transform: 'translateX(100px)' },
        '100%': { opacity: 1, transform: 'translateX(0)' },
    },
    // Keyframes for fade-out
    '@keyframes fadeOut': {
        '0%': { opacity: 1, transform: 'translateX(0)' },
        '100%': { opacity: 0, transform: 'translateX(100px)' },
    },
}, { name: 'NotifyToaster' });

export default function NotifyToaster() {
    const classes = useStyles();
    const { t } = useTranslation();
    const notifications = useApplicationStore((state) => state.notifications);
    const appMethods = useApplicationStore((state) => state.methods);
    const firstNotification = useRef(null);

    function remove(id) {
        appMethods.removeNotification(id);
    }

    useEffect(() => {
        if (firstNotification.current) {
            firstNotification.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [ notifications.length ]); // Run effect when the notifications change

    function renderSubItems(notify) {
        if (notify.components) {
            return <>
                {notify.components}
            </>;
        }

        return null;
    }

    function renderItems() {
        return notifications.map((notify, index) => {
            const css = [
                classes.contentSection,
            ];

            if (notify.removing) {
                css.push(classes.removing);
            }

            if (notify.onClick) {
                css.push(classes.clickable);
            }

            let title = null;
            if (notify?.title) {
                title = <div className={classes.title}>
                    <strong>{t(notify?.title, notify?.titleData)}</strong>
                </div>;
            }

            let message = null;
            if (notify?.message) {
                message = <div className={classes.message}>
                    {t(notify?.message, notify?.messageData)}
                </div>;
            }

            const isFirst = index === 0;

            return <article
                key={notify.id}
                className={classes.notify}
                onClick={notify.onClick}
                onMouseOver={() => {
                    clearTimeout(notify.clearTimeoutId);
                }}
                onMouseLeave={() => {
                    appMethods.removeNotification(notify.id, 3000);
                }}
                onTouchEnd={() => {
                    appMethods.removeNotification(notify.id, 3000);
                }}
            >
                <div className={classes.spacer}>
                    <div></div>
                </div>
                <section
                    className={css.join(' ')}
                    ref={isFirst ? firstNotification : null} // Attach ref to the last notification
                >
                    <div className={classes.contentSectionBackground}>
                        <Close className={classes.closeButton} onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            remove(notify.id);
                        }}></Close>
                        {title}
                        {message}
                        {renderSubItems(notify)}
                    </div>
                </section>
            </article>;
        });
    }

    return (
        <div className={classes.wrapper}>
            <section className={classes.notificationsBox}>
                {renderItems()}
            </section>
        </div>
    );
}
