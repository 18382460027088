export default function init() {
    if (!String.prototype.replaceAll) {
        function escapeStringRegexp(string) {
            if (typeof string !== 'string') {
                throw new TypeError('Expected a string');
            }

            // Escape characters with special meaning either inside or outside character sets.
            // Use a simple backslash escape when it’s always valid, and a `\xnn` escape when the simpler form would be disallowed by Unicode patterns’ stricter grammar.
            return string
                .replace(/[|\\{}()[\]^$+*?.]/g, '\\$&')
                .replace(/-/g, '\\x2d');
        }

        const emptyStringRegex = /(?:)/g;

        String.prototype.replaceAll = function(stringToReplace, searchValue, replaceValue) {
            if (typeof stringToReplace !== 'string') {
                throw new TypeError('Expected a string');
            }

            if (searchValue instanceof RegExp) {
                const { global: globalFlag } = searchValue;
                if (!globalFlag) {
                    throw new TypeError(
                        '`String.prototype.replaceAll` ponyfill called with a non-global RegExp argument',
                    );
                }
                return stringToReplace.replace(searchValue, replaceValue);
            }

            if (searchValue === '') {
                const value = stringToReplace.replace(emptyStringRegex, replaceValue);
                emptyStringRegex.lastIndex = 0;
                return value;
            }

            const stringToFind =
                typeof searchValue !== 'string' ? String(searchValue) : searchValue;

            return stringToReplace.replace(
                new RegExp(escapeStringRegexp(stringToFind), 'g'),
                replaceValue,
            );
        };
    }
}
